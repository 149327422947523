<template>
  <KDialog
    show-close
    max-width="580px"
    :visible="visible"
    :title="`Coleta #${this.form.id}`"
    :actions="buttons"
    @click:close="close"
    @click:save="save"
    @click:next="onNextClick"
    @click:previous="onPreviousClick"
  >
    <KForm ref="form">
      <!-- Dados da Unidade -->
      <v-row class="my-0">
        <v-col cols="12">
          <KLabel label="Unidade">
            {{ form.condominium_name }} - {{ form.unit_identification }}
          </KLabel>
        </v-col>
      </v-row>

      <v-row class="my-0">
        <!-- Captura imagem -->
        <v-col cols="12" class="py-0">
          <SectionHeader title="Coleta" />
        </v-col>
        <v-col cols="12" class="d-flex pt-0 justify-center">
          <v-card outlined class="overflow-hidden">
            <PickImage v-model="file" ref="pick_image" />
            <ErrorLabel :message="errors.file" />
          </v-card>
        </v-col>

        <!-- Pegar número da coleta -->
        <v-col cols="12" class="d-flex row ma-0 justify-center">
          <v-row class="mt-4">
            <v-col cols="12" class="py-0">
              <SectionHeader subtitle="Coleta atual" />
              <CollectInput
                v-model="form.collected_value"
                :rules="[$validation.isRequired]"
                :error-messages="errors.collected_value"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Mensagem de valor muito acima -->
      <v-expand-transition>
        <v-row v-if="vars.consumptionIncrease">
          <v-col>
            <KAlert warning title="Atenção">
              Esta coleta excedeu em 30% a coleta anterior. Verifique se o valor
              registrado está correto.
            </KAlert>
          </v-col>
        </v-row>
      </v-expand-transition>

      <!-- Mensagem de coleta atual meor que a anterior -->
      <v-expand-transition>
        <v-row v-if="vars.lastBiggerThanCurrent">
          <v-col>
            <KAlert error title="Atenção">
              A coleta atual não pode ser menor que a anterior
            </KAlert>
          </v-col>
        </v-row>
      </v-expand-transition>

      <!-- Dados da Coleta -->
      <v-row>
        <!-- Informações -->
        <v-col cols="12">
          <!-- <SectionHeader title="Coleta Atual" /> -->
          <v-row class="my-0">
            <!--  -->
            <v-col cols="12" class="">
              <KLabel label="Número do Medidor">
                {{ form.meter_number }}
              </KLabel>
            </v-col>

            <!--  -->
            <!-- <v-col cols="12" md="6" class="">
            <KLabel label="Medidor">asd</KLabel>
          </v-col> -->

            <!--  -->
            <v-col cols="12" md="6" class="">
              <KLabel label="Leitura Atual"
                >{{ vars.collected_value | precision(3) }} m³</KLabel
              >
            </v-col>

            <!--  -->
            <v-col cols="12" md="6" class="">
              <KLabel label="Leitura Anterior">
                {{ vars.last_collected | precision(3) }} m³
              </KLabel>
            </v-col>

            <!--  -->
            <v-col cols="12" md="6" class="">
              <KLabel label="Consumo Atual">
                {{ vars.consumed_value | precision(3) }} m³
              </KLabel>
            </v-col>

            <!-- Consumo anterior -->
            <v-col cols="12" md="6" class="">
              <KLabel label="Consumo Anterior">
                <!-- Se a coleta anterior foi uma coleta inicial, mostre a palabra inicial -->
                <template v-if="vars.last_is_initial"
                  ><span class="font-italic font-weight-thin">(Inicial)</span>
                </template>
                <template v-else>
                  {{ vars.last_consumed | precision(3) }} m³
                </template>
              </KLabel>
            </v-col>

            <!--  -->
            <v-col cols="12" md="6" class="">
              <KLabel label="Valor/m³">{{ vars.unit_price | currency }}</KLabel>
            </v-col>

            <!--  -->
            <v-col cols="12" md="6" class="">
              <KLabel label="Valor Consumido">{{
                vars.consumed_amount | currency
              }}</KLabel>
            </v-col>

            <!-- Coleta anterior -->
            <!-- <v-col cols="12" class="py-1">
            <SectionHeader title="Coleta Anterior" />
          </v-col> -->

            <!-- Comentário -->
            <v-col cols="12" class="py-1">
              <SectionHeader title="Comentário" />
            </v-col>

            <!--  -->
            <v-col cols="12" class="">
              <v-text-field
                :counter="255"
                outlined
                label="Comentário"
                v-model="form.comments"
                :error-messages="errors.comments"
                placeholder="Comentário sobre esta coleta"
              />
            </v-col>
          </v-row>
          <!-- {{ vars }} -->
        </v-col>
      </v-row>

      <!-- Dados do condomínio e do contrato de consumo -->
      <v-row>
        <!-- Dados do Condomínio -->
        <v-col cols="12">
          <SectionHeader
            title="Dados do Condomínio"
            :actions="[
              {
                // Ícone que será exibido
                icon: 'mdi-open-in-new',
                // Exibe um texto de ajuda
                label: 'Abrir página do condomínio',
                // Todos os parâmetros a seguir serão passados diretamente para o botão
                to: '/collect/condominiums/view/' + form.condominium_id,
                target: '_blank',
              },
            ]"
          />
          <v-card outlined>
            <v-card-title class="text-subtitle-1 py-1">
              {{ form.condominium_name }}
            </v-card-title>
            <v-card-text class="d-flex flex-row">
              <AddressFormatter
                :street="form.condominium_address_street"
                :number="form.condominium_address_number"
                :district="form.condominium_address_district"
                :city="form.condominium_address_city"
                :state="form.condominium_address_state"
                :zipcode="form.condominium_address_zipcode"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Dados da Unidade -->
        <v-col cols="12">
          <SectionHeader
            title="Dados da Unidade"
            :actions="[
              {
                // Ícone que será exibido
                icon: 'mdi-open-in-new',
                // Exibe um texto de ajuda
                label: 'Abrir página da Unidade',
                // Todos os parâmetros a seguir serão passados diretamente para o botão
                to: `/collect/condominiums/${form.condominium_id}/consumption_contracts/view/${form.contract_id}`,
                target: '_blank',
              },
            ]"
          />
          <v-card outlined>
            <v-card-title class="text-subtitle-1 py-1">
              {{ form.unit_identification }}
            </v-card-title>
            <v-card-text class="d-flex flex-row">
              {{ form.customer_name }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </KForm>

    <!-- Dialog dos Logs -->
    <!-- <DialogList ref="dialog_logs_list" /> -->
  </KDialog>
</template>

<script>
// import KChip from "@/components/KChip";
import KForm from "@/components/KForm";
import KAlert from "@/components/KAlert";
import KDialog from "@/components/KDialog";
// import KInputMoney from "@/components/KInput/Money";
import SectionHeader from "@/components/SectionHeader";
import AddressFormatter from "@/components/AddressFormatter";
import ErrorLabel from "@/components/ErrorLabel";

import KLabel from "@/components/KLabel";
// import ChipsList from "@/views/Collect/collections/_shared/ChipsList";

import PickImage from "@/components/Customize/PickImage";

import CollectInput from "./_shared/CollectInput";
// import DialogList from "@/views/Logs/DialogList";

export default {
  components: {
    // KChip,
    KForm,
    KAlert,
    KLabel,
    KDialog,
    // ChipsList,
    PickImage,
    ErrorLabel,
    // KInputMoney,
    CollectInput,
    SectionHeader,
    AddressFormatter

    //

    //
    // DialogList
  },

  data() {
    return {
      visible: false,
      form: {},
      errors: {},
      file: null
    };
  },

  computed: {
    /**
     * Retorna as informações para preencher
     * os dados de leitura atual, anterior, consumo atual,
     * anterior, valor do consumo, preço por m3 e
     * a variável que calcula se está muito alto
     */
    vars() {
      const { collected_value, last_collect, unit_price } = this.form;

      // Coleta anterior
      let last_collected = 0;
      let last_consumed = 0;
      let last_is_initial = false;
      if (last_collect) {
        last_collected = last_collect.collected || 0;
        last_consumed = last_collect.consumed || 0;
        last_is_initial = last_collect.is_initial;
      }

      // Recalcula o valor consumido, comparando a
      // coleta anterior e a coleta atual
      let consumed_value = 0;
      if (collected_value && last_collected) {
        consumed_value = collected_value - last_collected;
      }

      // Valor consumido em r$.
      // Compara com o valor consumido e multiplica pelo
      // valor do m³
      let consumed_amount = 0;
      if (consumed_value) {
        consumed_amount = consumed_value * unit_price;
      }

      // Se a leitura atual for menor que a anterior
      let lastBiggerThanCurrent = false;
      if (
        Number(collected_value) &&
        Number(last_collected) > Number(collected_value)
      ) {
        lastBiggerThanCurrent = true;
      }

      // Se a leitura atual é muito maior que o consumo anterior
      let consumptionIncrease = false;
      if (
        Number(collected_value) &&
        Number(last_consumed) &&
        consumed_value > last_consumed * 1.3
      ) {
        consumptionIncrease = true;
      }

      return {
        collected_value,
        consumed_value,
        last_collected,
        last_consumed,
        last_is_initial,
        unit_price,
        consumed_amount,
        lastBiggerThanCurrent,
        consumptionIncrease
      };
    },

    /**
     * Dados para salvar
     */
    uploadUrl() {
      const payload = {
        collected_value: this.form.collected_value,
        comments: this.form.comments || ""
      };

      const query = new URLSearchParams(payload).toString();
      return `/collections/${this.form.id}/collect?${query}`;
    },

    /**
     * Botões do dialog
     */
    buttons() {
      // const saved =
      return [
        {
          eventName: "click:previous",
          // label: "Anterior",
          icon: "mdi-chevron-left"
        },
        {
          eventName: "click:next",
          // label: "Próximo",
          icon: "mdi-chevron-right"
          // iconAfter: true
        },
        {
          eventName: "click:save",
          label: "Salvar Coleta",
          color: "accent"
        }
      ];
    }
  },

  methods: {
    /**
     * Salva a coleta
     */
    async save() {
      try {
        if (this.validate()) {
          // return;
          this.$loading.show("Aguarde enquanto os dados são salvos");
          const file = await this.$refs["pick_image"].getBlob();

          const { data } = await this.$api.upload(this.uploadUrl, file, {
            method: "PATCH"
          });

          this.$emit("saved", data.data);
          this.$message.success("Coleta registrada");
          this.$loading.hide();

          // console.log("teste", data.data);

          // } else {
          //   console.log("nao tá validao", this.$refs.form);
        }
      } catch (error) {
        this.$loading.hide();
        this.$message.serverError(error);
      }
    },

    validate() {
      try {
        let count = 0;
        const errors = {};
        // O valor coletado precisa ser maior que 0
        if (!this.form.collected_value || !Number(this.form.collected_value)) {
          count++;
          errors.collected_value = "Campo obrigatório";
        } else if (this.vars.lastBiggerThanCurrent) {
          count++;
          errors.collected_value = "Coleta atual menor que a anterior";
        } else {
          errors.collected_value = null;
        }

        // O tamanho do comentário não pode ser maior que 250
        if (this.form.comments && this.form.comments.length > 250) {
          count++;
          errors.comments = "Tamanho máximo: 250";
        } else {
          errors.comments = null;
        }

        // Precisa existir um arquivo
        if (!this.file) {
          count++;
          errors.file = "Precisa selecionar um arquivo";
        } else errors.file = null;

        // Verifica se é uma coleta cancelada.
        if (this.form.status === "cancelled") {
          this.$message.error("Não é possível editar uma coleta cancelada");
          return false;
        }

        this.errors = errors;
        if (count) this.$message.error("Verifique os campos obrigatórios");

        return count === 0;
      } catch (error) {
        console.log(error);
        return false;
      }
    },

    /**
     * Método de abertura do dialog
     */
    openToCollect(item) {
      this.form = { ...item };
      this.visible = true;

      if (item.file_id) {
        this.file = item.file_url;
      } else {
        this.file = null;
      }
    },

    /**
     * Ao clicar em navegar para o próximo
     */
    onNextClick() {
      // this.visible = false;
      this.$emit("click:next", this.form.id);
    },

    /**
     * Ao clicar em navegar para o anterior
     */
    onPreviousClick() {
      // this.visible = false;
      this.$emit("click:previous", this.form.id);
    },

    /**
     * Ao clicar em fechar
     */
    close() {
      this.visible = false;
      this.form = {};
      this.file = null;
    }
  }
};
</script>

<style>
</style>
