<template>
  <KPage>
    <SectionHeader title="Mês de Referência" />
    <!-- <KInputMonth /> -->
    <KMonthList @change="onChangeMonth" />

    <SectionHeader
      title="Lotes de Coleta"
      class="my-4"
      :actions="[
        {
          icon: 'mdi-refresh',
          label: 'Atualizar',
          eventName: 'click:refresh',
        },
      ]"
      @click:refresh="refresh"
    />

    <v-card :ripple="false" outlined v-if="items.length">
      <v-list class="py-0 bordered">
        <v-list-item
          v-for="item in items"
          :key="item.id"
          :to="`/collector/batch/${item.batch_id}`"
        >
          <v-list-item-avatar>
            <v-icon>mdi-city-variant</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.condominium_name }}</v-list-item-title>

            <v-list-item-subtitle>
              <KChip pink>Lote {{ item.batch_id }}</KChip>

              <!--  -->
              <KChip blue>{{
                item.collects_total | quantity("coletas", "coleta")
              }}</KChip>

              <!--  -->
              <KChip v-if="item.is_finished" green>Coleta concluída</KChip>

              <!--  -->
              <KChip v-if="!item.is_finished" orange>{{
                item.collects_pendents
                  | quantity("coletas pendentes", "coleta pendente")
              }}</KChip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <KAlert v-if="items.length === 0" info>
      Não foram gerados lotes para o mês selecionado
    </KAlert>
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import SectionHeader from "@/components/SectionHeader";
import KChip from "@/components/KChip";
import KAlert from "@/components/KAlert";

import KMonthList from "./_shared/KMonthList";

export default {
  name: "colletor-index",

  components: {
    KPage,
    KChip,
    KAlert,
    KMonthList,
    SectionHeader
  },

  data() {
    return {
      reference: "",
      month: "",
      year: "",
      items: {}
    };
  },

  computed: {},

  methods: {
    async load({ month, year }) {
      try {
        this.$loading.show();
        const { data } = await this.$api.get(
          `/collections/${year}/${month}/condominiums`
        );
        this.$loading.hide();
        // console.log(data);
        this.items = data.data;
      } catch (error) {
        this.$loading.hide();
        this.$message.serverError(error);
      }
    },

    onChangeMonth(payload) {
      // console.log(payload);
      this.month = payload.month;
      this.year = payload.year;
      this.load(payload);
    },

    refresh() {
      this.load({ month: this.month, year: this.year });
    }
  },

  mounted() {
    // this.load()
  }
};
</script>

