<template>
  <div>
    <v-menu v-model="visible" absolute offset-y style="max-width: 600px">
      <template v-slot:activator="{ on, attrs }">
        <v-card class="portrait" v-bind="attrs" v-on="on" outlined>
          <v-card-title class="pa-1 text-h6">
            <v-btn icon @click.stop="previous">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-spacer />
            {{ monthBR }}/{{ year }}
            <v-spacer />
            <v-btn icon @click.stop="next">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </template>

      <v-date-picker v-model="picker" type="month" no-title></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "KMonthList",

  props: {},

  data() {
    return {
      month: null,
      year: null,
      visible: false,
      picker: null
    };
  },

  watch: {
    picker(val) {
      // console.log(val)
      const [year, month] = val.split("-");
      this.month = Number(month);
      this.year = year;
      this.$emit("change", { month: this.month, year: this.year });
    }
  },

  computed: {
    monthBR() {
      return {
        1: "Janeiro",
        2: "Fevereiro",
        3: "Março",
        4: "Abril",
        5: "Maio",
        6: "Junho",
        7: "Julho",
        8: "Agosto",
        9: "Setembro",
        10: "Outubro",
        11: "Novembro",
        12: "Dezembro"
      }[this.month];
    }
  },

  methods: {
    next() {
      if (this.month < 12) {
        this.month++;
      } else {
        this.month = 1;
        this.year++;
      }
      // this.$nextTick(() => {
      this.setPicker();
      // })
    },
    previous() {
      if (this.month > 1) {
        this.month--;
      } else {
        this.month = 12;
        this.year--;
      }
      // this.$nextTick(() => {
      this.setPicker();
      // })
    },

    setPicker() {
      // this.$nextTick(() => {
      this.picker = `${this.year}-${String(this.month).padStart(2, "0")}`;
      // })
    }
  },

  mounted() {
    const date = new Date();
    this.month = date.getMonth() + 1;
    this.year = date.getFullYear();
    this.setPicker();
  }
};
</script>

<style lang="scss" scoped>
</style>

