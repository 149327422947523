<template>
  <div>
    <v-otp-input
      class="meter"
      :type="'number'"
      v-model="collect"
      :length="8"
      @change="onChange"
    />
    <ErrorLabel v-if="errorMessages" :message="errorMessages" />

    <!-- {{ isValid }} -->
  </div>
</template>

<script>
import ErrorLabel from "@/components/ErrorLabel";
export default {
  name: "collect-input",

  components: {
    ErrorLabel
  },

  props: {
    label: String,
    value: String,
    errorMessages: String
  },

  data() {
    return {
      collect: ""
    };
  },

  watch: {
    value() {
      // console.log('trocou o value');
      this.numberToOTP();
    }
    // collect(val) {
    //   console.log("trocou o collect", val);
    // }
  },

  computed: {
    // isValid() {
    //   const rules = this.rules;
    //   // console.log("é válido", rules[0](this.value));
    //   return rules.find(rule => {
    //     const ava = rule(this.value);
    //     if (ava !== true) {
    //       this.errorMessages = ava;
    //       return ava;
    //     }
    //     // return rule(this.value);
    //   });
    // }
  },

  methods: {
    // onFinish() {
    //   // console.log('onfinhos');
    //   // this.$emit('input', (this.collect / 1000).toFixed(3))
    // },
    onChange() {
      this.$emit("input", (this.collect / 1000).toFixed(3));
      // console.log('onblur');
    },
    numberToOTP() {
      // console.log(this.value, this.value.padStart(9, '0').replace('.', ''))
      if (this.value && this.value !== "0.000") {
        this.collect = String(this.value)
          .padStart(9, "0")
          .replace(".", "");
      } else {
        this.collect = null;
      }
    }
  },

  mounted() {
    // console.log("mount do input collect", this.value);
    // this.collect = this.value;
    this.numberToOTP();
  }
};
</script>

<style>
.meter.v-otp-input .v-input:nth-child(6) fieldset,
.meter.v-otp-input .v-input:nth-child(7) fieldset,
.meter.v-otp-input .v-input:nth-child(8) fieldset {
  border: 2px solid red;
}

.meter.v-otp-input .v-input:nth-child(1) fieldset,
.meter.v-otp-input .v-input:nth-child(2) fieldset,
.meter.v-otp-input .v-input:nth-child(3) fieldset,
.meter.v-otp-input .v-input:nth-child(4) fieldset,
.meter.v-otp-input .v-input:nth-child(5) fieldset {
  border: 2px solid black;
}
</style>
