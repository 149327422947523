var render = function render(){var _vm=this,_c=_vm._self._c;return _c('KPage',[_c('SectionHeader',{attrs:{"title":"Mês de Referência"}}),_c('KMonthList',{on:{"change":_vm.onChangeMonth}}),_c('SectionHeader',{staticClass:"my-4",attrs:{"title":"Lotes de Coleta","actions":[
      {
        icon: 'mdi-refresh',
        label: 'Atualizar',
        eventName: 'click:refresh',
      },
    ]},on:{"click:refresh":_vm.refresh}}),(_vm.items.length)?_c('v-card',{attrs:{"ripple":false,"outlined":""}},[_c('v-list',{staticClass:"py-0 bordered"},_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.id,attrs:{"to":`/collector/batch/${item.batch_id}`}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-city-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.condominium_name))]),_c('v-list-item-subtitle',[_c('KChip',{attrs:{"pink":""}},[_vm._v("Lote "+_vm._s(item.batch_id))]),_c('KChip',{attrs:{"blue":""}},[_vm._v(_vm._s(_vm._f("quantity")(item.collects_total,"coletas", "coleta")))]),(item.is_finished)?_c('KChip',{attrs:{"green":""}},[_vm._v("Coleta concluída")]):_vm._e(),(!item.is_finished)?_c('KChip',{attrs:{"orange":""}},[_vm._v(_vm._s(_vm._f("quantity")(item.collects_pendents,"coletas pendentes", "coleta pendente")))]):_vm._e()],1)],1)],1)}),1)],1):_vm._e(),(_vm.items.length === 0)?_c('KAlert',{attrs:{"info":""}},[_vm._v(" Não foram gerados lotes para o mês selecionado ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }