<template>
  <KPage>
    <SectionHeader
      title="Coletas do Lote "
      class="my-4"
      :actions="[
        {
          icon: 'mdi-refresh',
          label: 'Atualizar',
          eventName: 'click:refresh',
        },
      ]"
      @click:refresh="load"
    />

    <v-card :ripple="false" outlined v-if="items.length" class="mb-6">
      <v-list class="py-0 bordered">
        <ListItem
          v-for="item in items"
          :key="`item-${item.id}`"
          :item="item"
          hide-actions
          @click:item="onItemClick(item)"
        />
      </v-list>
    </v-card>
    <v-card v-if="items.length === 0" outlined>
      <v-card-text class="d-flex flex-col">
        <v-icon left>mdi-information</v-icon>
        <div>Não foram gerados lotes para o mês selecionado</div>
      </v-card-text>
    </v-card>

    <DialogCollector
      ref="dialog_collector"
      @saved="onSaveCollect"
      @click:next="onNextClick"
      @click:previous="onPreviousClick"
    />
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import SectionHeader from "@/components/SectionHeader";
import KChip from "@/components/KChip";

import ListItem from "@/views/Collect/collections/ListItem";
import DialogCollector from "./DialogCollector";

export default {
  name: "colletor-index",

  components: {
    KPage,
    KChip,
    ListItem,
    SectionHeader,
    DialogCollector
  },

  data() {
    return {
      items: {}
    };
  },

  computed: {
    batch_id() {
      return this.$route.params.batch_id;
    }
  },

  methods: {
    /**
     * Ao salvar um item na lista
     */
    onSaveCollect(payload) {
      try {
        const idx = this.items.findIndex(item => item.id === payload.id);
        // console.log("alterour", idx, payload);
        this.$set(this.items, idx, payload);
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * Ao clicar no item
     */
    onItemClick(item) {
      // console.log("clicou no bicho", item);
      this.$refs.dialog_collector.openToCollect(item);
    },

    /**
     * Ao clicar em avançar
     */
    onNextClick(id) {
      const idx = this.items.findIndex(item => item.id === id);
      // console.log("findindex", idx);
      if (idx === this.items.length - 1) {
        this.$message.show("Já está no final da lista");
      } else {
        this.$refs.dialog_collector.close();
        setTimeout(() => {
          this.onItemClick(this.items[idx + 1]);
        }, 300);
      }
    },

    /**
     * Ao clicar em voltar
     */
    onPreviousClick(id) {
      const idx = this.items.findIndex(item => item.id === id);
      if (idx === 0) {
        this.$message.show("Já está no primeiro item da lista");
      } else {
        this.$refs.dialog_collector.close();
        setTimeout(() => {
          this.onItemClick(this.items[idx - 1]);
        }, 300);
      }
    },

    async load() {
      try {
        this.$loading.show();

        const { data } = await this.$api.get("/collections", {
          batch: this.batch_id,
          limit: 999
        });

        // console.log(data);
        this.items = data.data.data;
        this.$app.title(`Coletas do Lote #${this.batch_id}`);
        this.$loading.hide();
      } catch (error) {
        this.$loading.hide();
        this.$message.serverError(error);
      }
    }
  },

  mounted() {
    this.load();
  }
};
</script>
