var render = function render(){var _vm=this,_c=_vm._self._c;return _c('KPage',[_c('SectionHeader',{staticClass:"my-4",attrs:{"title":"Coletas do Lote ","actions":[
      {
        icon: 'mdi-refresh',
        label: 'Atualizar',
        eventName: 'click:refresh',
      },
    ]},on:{"click:refresh":_vm.load}}),(_vm.items.length)?_c('v-card',{staticClass:"mb-6",attrs:{"ripple":false,"outlined":""}},[_c('v-list',{staticClass:"py-0 bordered"},_vm._l((_vm.items),function(item){return _c('ListItem',{key:`item-${item.id}`,attrs:{"item":item,"hide-actions":""},on:{"click:item":function($event){return _vm.onItemClick(item)}}})}),1)],1):_vm._e(),(_vm.items.length === 0)?_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"d-flex flex-col"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-information")]),_c('div',[_vm._v("Não foram gerados lotes para o mês selecionado")])],1)],1):_vm._e(),_c('DialogCollector',{ref:"dialog_collector",on:{"saved":_vm.onSaveCollect,"click:next":_vm.onNextClick,"click:previous":_vm.onPreviousClick}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }